.App {
    text-align: center;
  }
  .App a{
    text-decoration: none;
    color: darkblue;
  }
  .App h1 {
      /* color: white; */
      /* background-color: darkgreen; */
      /* margin: 0; */
  }
  .Nav {
    text-align: center;
    padding: 1em 0;
    border-bottom: 1px solid darkblue;
    box-shadow: 0 2px 2px darkblue;
    display: flex;
    flex-direction: row;
    /* align-items:stretch; */
    align-items: center;
    justify-content: center;
    /* position: fixed; */
    width: 100%;
    background-color: rgb(0, 42, 77);
    color: white;
  }
  .Nav span {
    padding: 0 20px;
  }
  .Link {
    color: darkslateblue;
  }
  .Logo {
    width: 40px;
    height: 40px;
    /* box-shadow: 3px 3px 2px white; */
  }
  .Post1 {
    background-color: lightblue;
    height: 600px;
    box-sizing: content-box;
  }
  .Post1 img{
    margin: 0 800px 0 0;
    width: 42%
  }
  .Post2 {
      background-color: salmon;
      height: 599px;
  }
  .Post2 img{
    margin: 0 0 0 800px;
    width: 42%;
  }
  .Footer {
    border-top: 1px solid darkblue;
    box-shadow: 0 -2px 2px darkblue;
    padding-top: 10px;
  }
  .Footer div {
    display: flex;
    flex-direction: row;
    /* align-items:stretch; */
    align-items: center;
    justify-content: space-evenly;
  }
  .Footer a {
    margin: 5px 0;
    animation: all 1s ease-in;
  }
  @media (max-width: 1600px) {
    .Post1 img,
    .Post2 img{
      width: 100%;
      height: auto;
      margin: 0;
    }
    .Post1, .Post2 {
      height: auto;
      width: auto;
    }
  }