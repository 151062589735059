.Contact {
    margin: 2em 0;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.Contact input,
.Contact textarea {
    margin: 10px;
    border: 1px dotted darkblue;
}
.Submit {
    padding: 10px;
    background-color: blue;
    color: white;
    border: 1px solid darkblue;
    cursor: pointer;
}
.Sumbit:hover {
    background-color: lightblue;
}
